<h3 mat-dialog-title>BOI Filings</h3>
<mat-dialog-content>
  <div
    class="print-out-container"
    (click)="pdf.generate()"
    (keydown)="pdf.generate()"
    tabindex="0"
  >
    <div id="print-out"></div>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let filing">
        {{ filing.snapshotDate | date }}
      </td>
    </ng-container>

    <ng-container matColumnDef="reportType">
      <th mat-header-cell *matHeaderCellDef>Report Type</th>
      <td mat-cell *matCellDef="let filing">{{ filing.reportType }}</td>
    </ng-container>

    <ng-container matColumnDef="view">
      <th mat-header-cell *matHeaderCellDef>View PDF</th>
      <td mat-cell *matCellDef="let filing; let i = index">
        <button
          mat-icon-button
          matTooltip="Get Transcript"
          [disabled]="currentViewedEntityIndex === i"
          (click)="renderPdf(filing.entitySnapshot, i, $event)"
        >
          <mat-icon>picture_as_pdf</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let filing">
        <span *ngIf="filing.reportType === 'Handoff'">N/A</span>
        <button
          *ngIf="
            filing.reportType !== 'Handoff' &&
            (!filing.submissionStatus ||
              filing.submissionStatus === 'submission_processing' ||
              filing.submissionStatus === 'submission_initiated' ||
              filing.submissionStatus === 'submission_validation_passed')
          "
          mat-icon-button
          matTooltip="Check Status"
          (click)="checkSubmissionStatus(filing)"
        >
          <mat-icon>refresh</mat-icon>
        </button>
        <div></div>
        <button
          color="warn"
          *ngIf="
            filing.submissionStatus === 'submission_rejected' ||
            filing.submissionStatus === 'submission_validation_failed'
          "
          mat-icon-button
          matTooltip="View Errors"
          (click)="checkSubmissionStatus(filing)"
        >
          <mat-icon>error</mat-icon>
        </button>
        <button
          *ngIf="filing.submissionStatus === 'submission_accepted'"
          mat-icon-button
          matTooltip="View Errors"
          (click)="checkSubmissionStatus(filing)"
        >
          <mat-icon [ngStyle]="{ color: 'green' }">check_circle</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="transcript">
      <th mat-header-cell *matHeaderCellDef>Get Transcript</th>
      <td mat-cell *matCellDef="let filing">
        <span *ngIf="filing.reportType === 'Handoff'">N/A</span>
        <button
          *ngIf="filing.reportType !== 'Handoff'"
          mat-icon-button
          color="primary"
          matTooltip="Get Transcript"
          [disabled]="
            filing.submissionStatus === 'submission_validation_failed'
          "
          (click)="getTranscript(filing.processId, filing.entitySnapshot.id)"
        >
          <mat-icon>download</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-dialog-content>
